import { useRouter } from 'next/router';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Box } from '@bridebook/ui';
import BridebookLogo from '@bridebook/ui/src/components/svg/bridebook-logo';
import { Link } from 'app-shared/components/link/link';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import CollaborationFlowEndAnimation from 'components/auth/animations/collaboration-flow-end-animation';
import LoginComponent from 'components/auth/signup-container/components/login-component';
import SignupComponent from 'components/auth/signup-container/components/signup-component';
import SignupMobileWelcomeComponent from 'components/auth/signup-container/components/signup-mobile-welcome-component/signup-mobile-welcome-component';
import { getScreenType } from 'lib/app/selectors';
import { onAuthFormFieldChange } from 'lib/auth/actions';
import { useSignUpHelpers } from 'lib/auth/hooks/use-signup-hooks';
import { isCollaboratorInvite } from 'lib/auth/selectors';
import { UrlHelper } from 'lib/url-helper';
import { useDispatch, useSelector } from 'lib/utils';
import styles from './signup-container.style';

const SignupContainer = () => {
  const market = useMarket();
  const imgPostfix = market.language;
  const isCollaboratorsInvite = useSelector(isCollaboratorInvite);
  const [collaborationAnimationVisible, setCollaborationAnimationVisible] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const {
    query: { welcome },
  } = router;

  const showMobileWelcome = welcome === 'show';

  const { isSignUp } = useSignUpHelpers();
  const { isMobile } = useSelector(getScreenType);

  const showCollaborationAnimationVisible = useCallback((show: boolean) => {
    setCollaborationAnimationVisible(show);
  }, []);

  useEffect(() => {
    dispatch(onAuthFormFieldChange({ target: { name: 'countryCode', value: market.country } }));
  }, [dispatch, market.country]);

  const SignUpViewComponent = () =>
    isSignUp ? (
      <SignupComponent
        showCollaborationAnimationVisible={
          isCollaboratorsInvite ? showCollaborationAnimationVisible : undefined
        }
      />
    ) : (
      <LoginComponent showCollaborationAnimationVisible={showCollaborationAnimationVisible} />
    );

  return (
    <>
      {collaborationAnimationVisible && isCollaboratorsInvite && <CollaborationFlowEndAnimation />}
      <Box style={styles.wrapper}>
        <Box style={styles.logo}>
          <Link href={UrlHelper.index}>
            <BridebookLogo color="indigoCrush" width={180} />
          </Link>
        </Box>
        {isMobile && showMobileWelcome ? (
          <SignupMobileWelcomeComponent />
        ) : (
          <>
            <Box style={styles.auth}>
              <Box style={styles.signupContent} data-name="signupContent">
                <SignUpViewComponent />
              </Box>
            </Box>
            <Box style={styles.image(imgPostfix)} />
          </>
        )}
      </Box>
    </>
  );
};

export default memo(SignupContainer);
