import { FelaCSS, FelaCSSWithCustomSelectors } from '../../../components/fela/flowtypes';
import { colors } from '../../../themes/variables';
import { ISharedTypes as IStyleProps } from './well';

interface IStyles {
  wrapper: FelaCSS;
  container: FelaCSS;
  contentWrap: FelaCSS;
  headingStyle: FelaCSS;
  iconWrapStyle: FelaCSSWithCustomSelectors;
  childrenWrap: FelaCSS;
  onCloseButton: FelaCSS;
  chevronWrap: FelaCSS;
}

export const getThemeColor = (theme?: string): { primary: string; lighten: string } => {
  const indigoCrush = {
    primary: colors.indigoCrush,
    lighten: colors.indigoCrush04,
  };

  switch (theme) {
    case 'teal':
      return {
        primary: colors.teal120,
        lighten: colors.teal20,
      };
    case 'green':
      return {
        primary: colors.forest,
        lighten: colors.forest10,
      };
    case 'blue':
      return indigoCrush;
    case 'lightBlue':
      return {
        primary: colors.indigoCrush40,
        lighten: colors.indigoCrush10,
      };
    case 'red':
      return {
        primary: colors.amber,
        lighten: colors.amber10,
      };
    case 'pinkJoy':
      return {
        primary: colors.blushTangerine120,
        lighten: colors.pinkJoy40,
      };
    case 'blushTangerine':
      return {
        primary: colors.blushTangerine120,
        lighten: colors.blushTangerine40,
      };
    case 'indigo': {
      return {
        primary: colors.indigoCrush20,
        lighten: colors.indigoCrush20,
      };
    }
    case 'peachRose':
      return {
        primary: colors.peachRose120,
        lighten: colors.peachRose20,
      };
    default:
      return indigoCrush;
  }
};

const styles = ({ size, iconSize, theme, noPadding, withChevron }: IStyleProps): IStyles => {
  const themeColor = getThemeColor(theme);

  return {
    wrapper: {
      display: 'inline-flex',
      width: size === 'half' ? '50%' : '100%',
      borderRadius: '12px',
      overflow: 'hidden',

      maxWidthMobile: {
        display: 'flex',
        width: '100%',
      },
    },

    container: {
      flexDirection: 'row',
      alignItems: 'center',
      ...(!noPadding && {
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '16px',
        paddingBottom: '16px',
      }),
      borderRadius: '12px',
      backgroundColor: themeColor.lighten,
      ...(withChevron && {
        paddingRight: 8,
      }),
    },

    contentWrap: {
      flexGrow: 1,
    },

    headingStyle: {
      marginTop: '0',
      marginBottom: '0',
      fontDefaultSemiBold: 16,
      lineHeight: '20px',
      color: themeColor.primary,
    },

    iconWrapStyle: {
      marginRight: '16px',
      width: `${iconSize}px`,
      height: `${iconSize}px`,

      '> div': {
        '> svg': {
          '> path': {
            fill: themeColor.primary,
          },
        },
      },
    },

    childrenWrap: {
      flexDirection: 'column',
      width: '100%',
      fontDefault: 14,
    },
    onCloseButton: {
      paddingLeft: 4,
      cursor: 'pointer',
    },

    chevronWrap: {
      position: 'absolute',
      right: '12px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  };
};

export default styles;
