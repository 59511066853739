import { colors } from '../../../themes/variables';
import { FelaCSS, FelaCSSWithCustomSelectors } from '../../fela/flowtypes';

interface IStyles {
  wrapper: FelaCSSWithCustomSelectors;
  innerWrapper: FelaCSS;
  error: FelaCSS;
  helper: FelaCSS;
  input: FelaCSS | FelaCSSWithCustomSelectors;
  label: FelaCSS;
  inputWrapper: FelaCSSWithCustomSelectors;
  clearBtn: FelaCSS;
  startIconWrapper: FelaCSS;
  cross: FelaCSS;
  container: FelaCSS;
  underline: FelaCSS;
}

interface IStylesArgs {
  hasError: boolean;
  hasLabel: boolean;
  disabled?: boolean;
  isActive?: boolean;
  wrapperStyle: FelaCSS | FelaCSSWithCustomSelectors;
  containerStyle: FelaCSS | FelaCSSWithCustomSelectors;
  inputStyle: FelaCSS | FelaCSSWithCustomSelectors;
  readOnly?: boolean;
  variant?: 'default' | 'borderless';
}

const borderColorConfig = {
  default: {
    error: colors.alerts,
    focus: colors.indigoCrush,
    hover: colors.space,
    normal: colors.space40,
  },
  borderless: {
    error: colors.alerts,
    active: colors.space30,
    disabled: colors.space04,
    normal: colors.space15,
    hover: colors.space40,
    focus: colors.indigoCrush,
  },
};

const getBorderColor = ({
  variant,
  hasError,
  isActive,
  disabled,
}: {
  variant: 'default' | 'borderless';
  hasError: boolean;
  isActive?: boolean;
  disabled?: boolean;
}): string => {
  if (hasError) return borderColorConfig[variant].error;
  if (variant === 'borderless') {
    if (isActive) return borderColorConfig.borderless.active;
    if (disabled) return borderColorConfig.borderless.disabled;
  }
  return borderColorConfig[variant].normal;
};

const styles = ({
  hasError,
  hasLabel,
  disabled,
  isActive,
  wrapperStyle,
  containerStyle,
  inputStyle,
  readOnly,
  variant = 'default',
}: IStylesArgs): IStyles => ({
  container: containerStyle,
  wrapper: {
    border:
      variant === 'borderless' ? 'none' : `1px solid ${getBorderColor({ variant, hasError })}`,
    borderRadius: variant === 'borderless' ? 0 : hasLabel ? 16 : 12,
    ...(variant === 'borderless' && {
      borderBottom: `1px solid ${getBorderColor({ variant, hasError, isActive, disabled })}`,
      ':hover': !disabled && {
        borderBottom: `1px solid ${borderColorConfig.borderless.hover}`,
      },
      ':focus-within': !disabled && {
        borderBottom: `1px solid ${borderColorConfig.borderless.focus}`,
      },
    }),
    ...(!disabled &&
      !hasError &&
      variant === 'default' && {
        ':focus-within': { border: `1px solid ${borderColorConfig.default.focus}` },
        ':hover': { border: `1px solid ${borderColorConfig.default.hover}` },
        ':focus': { border: `1px solid ${borderColorConfig.default.focus}` },
      }),
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    backgroundColor:
      variant === 'borderless' ? 'transparent' : disabled ? colors.space04 : colors.white,
    ...wrapperStyle,
  },

  innerWrapper: {
    width: '100%',
    position: 'relative',
    ...(variant === 'borderless' && {
      paddingBottom: hasError ? 1 : 0,
    }),
    ...(variant === 'default' && {
      paddingVertical: 2,
      paddingLeft: 4,
      marginRight: 4,
    }),
  },

  error: {
    fontDefaultSemiBold: 12,
    color: colors.alerts,
    maxWidth: '100%',
    ...(variant === 'borderless' && {
      marginTop: 2,
    }),
    ...(variant === 'default' && {
      paddingTop: 1,
      paddingHorizontal: 4,
      margin: 0,
    }),
  },

  helper: {
    paddingTop: 1,
    paddingHorizontal: 4,
    fontDefaultSemiBold: 12,
    color: colors.space60,
    maxWidth: '100%',
  },

  input: {
    border: 'none',
    fontSize: 16,
    width: '100%',
    background: 'transparent',
    color: disabled ? colors.space40 : colors.space,
    outline: 'none',
    ...(variant === 'borderless' && {
      paddingBottom: 3,
    }),
    ...(variant === 'default' && {
      paddingLeft: 0,
    }),
    ...inputStyle,

    '::placeholder': {
      color: colors.space40,
    },
    ...(readOnly && { cursor: 'pointer' }),
  },

  label: {
    fontDefaultSemiBold: 12,
    ...(variant === 'borderless' && {
      color: hasError ? colors.alerts : colors.space75,
      marginBottom: 2,
    }),
    ...(variant === 'default' && {
      display: 'inline',
      color: disabled ? colors.space75 : colors.space,
    }),
  },

  inputWrapper: {
    position: 'relative',
    ...(variant === 'default' && {
      display: 'flex',
      flexDirection: 'row',
      fontDefault: 16,
      gap: 4,
      alignItems: 'center',
    }),
  },

  clearBtn: {
    paddingVertical: 4,
    paddingRight: 4,
    cursor: 'pointer',
  },

  startIconWrapper: {
    paddingRight: 2,
  },

  cross: {
    paddingRight: 4,
    cursor: 'pointer',
  },

  underline: {
    display: 'none',
  },
});

export default styles;
