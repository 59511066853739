import { BasicStore } from 'app-basic/lib/types';
import { createDeepEqualSelector } from 'cms-suppliers/lib/utils/selectors';
import { createSelector } from 'reselect';
import { getScreenTypeByWidth } from '@bridebook/ui/src/utils/get-screen-type-by-width';

const getAppState = (state: BasicStore) => state.app;
const getDevice = createSelector(getAppState, ({ device }) => device);

export const getPathname = createSelector(getAppState, ({ pathname }) => pathname);
export const getCanonicalLink = createSelector(getAppState, ({ canonicalLink }) => canonicalLink);
export const getAlternateLinks = createSelector(
  getAppState,
  ({ alternateLinks }) => alternateLinks,
);
export const getPreviousPath = createSelector(getAppState, ({ previousPath }) => previousPath);

const getDeviceWidth = createSelector(getDevice, (device) => device.width);

/** Based on user-agent from server */
const getIsMobileUA = createSelector(getDevice, ({ isMobileUA }) => isMobileUA);
const getIsTabletUA = createSelector(getDevice, ({ isTabletUA }) => isTabletUA);

/**
 * Returns screen type (mobile, tablet, desktop) for hiding components from the DOM.
 * Screens are aligned with CSS breakpoints.
 */
export const getScreenType = createDeepEqualSelector(
  createSelector([getDeviceWidth, getIsMobileUA, getIsTabletUA], (width, isMobileUA, isTabletUA) =>
    getScreenTypeByWidth({ width, isMobileUA, isTabletUA }),
  ),
  (obj) => obj,
);

export const getIsMobile = createSelector(getScreenType, ({ isMobile }) => isMobile);
export const getIsTablet = createSelector(getScreenType, ({ isTablet }) => isTablet);
export const getIsDesktop = createSelector(getScreenType, ({ isDesktop }) => isDesktop);

export const getHost = createSelector(getDevice, ({ host }) => host);
export const getIsCordova = createSelector(getDevice, ({ isCordova }) => isCordova);
export const getIsMainLiveCDN = createSelector(getDevice, ({ isMainLiveCDN }) => isMainLiveCDN);
